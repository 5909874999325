<template>
  <div class="home-container">
    <div class="home-container01">
      <header data-role="Header" class="home-header">
        <img
          alt="logo"
          src="/playground_assets/f464d58c0ac4465a6d2141623af20120-1400w.png"
          class="home-image"
        />
        <div class="home-container02">
          <div class="home-container03">
            <a href="#quemsomos" class="home-link">Quem somos</a>
            <a href="#areas" class="home-link01">Áreas de atuação</a>
            <a href="#contato" class="home-link02">Contato</a>
          </div>
        </div>
        <div class="home-icon-group">
          <a
            href="https://api.whatsapp.com/send/?phone=5553933002415&amp;text&amp;type=phone_number&amp;app_absent=0"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg viewBox="0 0 877.7142857142857 1024" class="home-icon">
              <path
                d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/hdd.adv/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg viewBox="0 0 877.7142857142857 1024" class="home-icon02">
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/hdd-adv/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg viewBox="0 0 877.7142857142857 1024" class="home-icon04">
              <path
                d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
              ></path>
            </svg>
          </a>
        </div>
      </header>
    </div>
    <div class="home-container04">
      <span class="home-text">
        <span class="home-text01">Atendimento jurídico</span>
        <br class="home-text02" />
        <span class="home-text03">personalizado</span>
        <br class="home-text04" />
        <br class="home-text05" />
      </span>
    </div>
    <div class="home-container05"></div>
    <div class="home-container06">
      <span id="quemsomos" class="home-text06">Quem somos</span>
    </div>
    <div class="home-container07">
      <div class="home-container08">
        <div class="home-container09">
          <img
            alt="image"
            src="/playground_assets/d7f51d518f1221d4ef64d31fba6bb644-700w.png"
            loading="lazy"
            class="home-image1"
          />
        </div>
        <div class="home-container10">
          <span class="home-text07">
            <span>THIAGO PINTO DAMAS</span>
            <br />
            <span>OAB-RS 108.476</span>
          </span>
        </div>
      </div>
      <div class="home-container11">
        <div class="home-container12">
          <img
            alt="image"
            src="/playground_assets/f7c348cec934ef841acda2352b8f958d-700w.png"
            loading="lazy"
            class="home-image2"
          />
        </div>
        <div class="home-container13">
          <span class="home-text11">
            <span>MELISSA PEREIRA DAS NEVES DE MACEDO</span>
            <br />
            <span>
              OAB-RS 116.145
              <span v-html="rawz53a"></span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="home-container14"></div>
    <div class="home-container15">
      <span id="areas" class="home-text15">Áreas de atuação</span>
    </div>
    <div class="home-container16">
      <div class="home-container17">
        <div class="home-container18">
          <div class="home-container19">
            <span class="home-text16">Direito Civil</span>
          </div>
        </div>
        <div class="home-container20">
          <div class="home-container21">
            <span class="home-text17">
              <span>Sucessões e</span>
              <br />
              <span>Família</span>
            </span>
          </div>
        </div>
      </div>
      <div class="home-container22">
        <div class="home-container23">
          <div class="home-container24">
            <span class="home-text21">
              <span>Assessoria</span>
              <br />
              <span>Jurídica</span>
              <br />
              <span>Judicial</span>
              <br />
            </span>
          </div>
        </div>
        <div class="home-container25">
          <div class="home-container26">
            <span class="home-text28">
              <span>Assessoria</span>
              <br />
              <span>Jurídica</span>
              <br />
              <span>Extrajudicial</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-container27">
      <span id="contato" class="home-text34">Contato</span>
    </div>
    <div class="home-container28">
      <div class="home-container29">
        <div class="home-container30">
          <img
            alt="image"
            src="/playground_assets/0aaf9533daed1a66a8b67b2ecae6c7ab-200h.png"
            class="home-image3"
          />
          <div class="home-container31">
            <div class="home-icon-group1">
              <a
                href="https://api.whatsapp.com/send/?phone=5553933002415&amp;text&amp;type=phone_number&amp;app_absent=0"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon06">
                  <path
                    d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/hdd.adv/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon08">
                  <path
                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/hdd-adv/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon10">
                  <path
                    d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div class="home-container32">
            <a
              href="https://api.whatsapp.com/send/?phone=5553933002415&amp;text&amp;type=phone_number&amp;app_absent=0"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link09"
            >
              <span>(53) 93300-2415</span>
              <br />
              <span>Atendimento com hora marcada</span>
            </a>
          </div>
        </div>
        <router-link to="/poltica-privacidade" class="home-navlink">
          Termos de uso do site
        </router-link>
      </div>
      <span class="home-text38">
        <span>Porto de Gale Business Center, sala 612</span>
        <br />
        <span>Rua Barão de Cotegipe, nº 443, Centro, Rio Grande/RS</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data() {
    return {
      rawz53a: ' ',
    }
  },

  metaInfo: {
    title: 'Hamilton, Damas & De Macedo Advocacia',
    meta: [
      {
        name: 'description',
        content:
          'Advogado Advogada Direito Civil Família Sucessões Herança Inventário Doação Testamento Compra Venda Imóvel Planejamento Cartório Justiça Processo Contrato',
      },
      {
        property: 'og:title',
        content: 'Hamilton, Damas & De Macedo Advocacia',
      },
      {
        property: 'og:description',
        content:
          'Advogado Advogada Direito Civil Família Sucessões Herança Inventário Doação Testamento Compra Venda Imóvel Planejamento Cartório Justiça Processo Contrato',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/4c2dbd79-e313-455e-8bdb-dc5001b85970/270b3fb6-aaa5-49f0-bc10-b73e2c77e900?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-amarelo);
}
.home-container01 {
  width: 100%;
  height: 201px;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  background-color: #15294e;
}
.home-header {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image {
  height: 75%;
  object-fit: cover;
}
.home-container02 {
  flex: 0 0 auto;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.home-link {
  color: var(--dl-color-primary-amarelo);
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
  padding-right: var(--dl-space-space-sixunits);
  text-decoration: none;
}
.home-link01 {
  color: var(--dl-color-primary-amarelo);
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
  padding-right: var(--dl-space-space-sixunits);
  text-decoration: none;
}
.home-link02 {
  color: var(--dl-color-primary-amarelo);
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
  text-decoration: none;
}
.home-icon {
  fill: var(--dl-color-primary-amarelo);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-icon02 {
  fill: var(--dl-color-primary-amarelo);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-icon04 {
  fill: var(--dl-color-primary-amarelo);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.home-container04 {
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  align-items: flex-start;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/28d56e6a40a06701821d55091c8fb1d3-1500w.jpg");
}
.home-text {
  top: 0px;
  left: 0px;
  color: var(--dl-color-primary-azul);
  position: absolute;
  align-self: flex-start;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
  line-height: 1.5;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-twounits);
  text-transform: uppercase;
  background-color: var(--dl-color-primary-amarelo);
}
.home-text01 {
  font-size: 16px;
  text-transform: uppercase;
}
.home-text02 {
  font-size: 16px;
  text-transform: uppercase;
}
.home-text03 {
  font-size: 16px;
  text-transform: uppercase;
}
.home-text04 {
  font-size: 16px;
  text-transform: capitalize;
}
.home-text05 {
  font-size: 24px;
  text-transform: uppercase;
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-azul);
}
.home-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text06 {
  color: var(--dl-color-primary-azul);
  font-size: 42px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
}
.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-container08 {
  flex: 0 0 auto;
  width: 50%;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-image1 {
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
}
.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text07 {
  color: rgb(21, 41, 78);
  align-self: center;
  text-align: center;
  font-family: Montserrat;
}
.home-container11 {
  flex: 0 0 auto;
  width: 50%;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-image2 {
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
}
.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text11 {
  color: rgb(21, 41, 78);
  align-self: center;
  text-align: center;
  font-family: Montserrat;
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 50px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-azul);
}
.home-container15 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text15 {
  color: var(--dl-color-primary-azul);
  font-size: 42px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
}
.home-container16 {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  background-image: url("/playground_assets/425c6aa23582842666896becc8b22c89-1500w.png");
}
.home-container17 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container19 {
  flex: 0 0 auto;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--dl-color-primary-amarelo);
}
.home-text16 {
  color: var(--dl-color-primary-azul);
  font-size: 32px;
  align-self: center;
  text-align: center;
  font-family: Montserrat;
}
.home-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container21 {
  flex: 0 0 auto;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--dl-color-primary-amarelo);
}
.home-text17 {
  color: var(--dl-color-primary-azul);
  font-size: 32px;
  align-self: center;
  text-align: center;
  font-family: Montserrat;
}
.home-container22 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container24 {
  flex: 0 0 auto;
  width: 50%;
  height: 50%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--dl-color-primary-amarelo);
}
.home-text21 {
  color: var(--dl-color-primary-azul);
  font-size: 32px;
  align-self: center;
  text-align: center;
  font-family: Montserrat;
}
.home-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container26 {
  flex: 0 0 auto;
  width: 50%;
  height: 50%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
  background-color: var(--dl-color-primary-amarelo);
}
.home-text28 {
  color: var(--dl-color-primary-azul);
  font-size: 32px;
  align-self: center;
  text-align: center;
  font-family: Montserrat;
}
.home-container27 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text34 {
  color: var(--dl-color-primary-azul);
  font-size: 42px;
  font-family: Montserrat;
  font-weight: 800;
  text-transform: capitalize;
}
.home-container28 {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary-amarelo);
}
.home-container29 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container30 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image3 {
  width: 66%;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon-group1 {
  align-self: center;
}
.home-icon06 {
  fill: var(--dl-color-primary-azul);
  width: var(--dl-size-size-small);
  height: auto;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-icon08 {
  fill: var(--dl-color-primary-azul);
  width: var(--dl-size-size-small);
  height: auto;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-icon10 {
  fill: var(--dl-color-primary-azul);
  width: var(--dl-size-size-small);
  height: auto;
  text-decoration: none;
}
.home-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-link09 {
  color: var(--dl-color-primary-azul);
  font-size: 24px;
  align-self: center;
  text-align: center;
  font-family: Montserrat;
  text-decoration: none;
}
.home-navlink {
  color: var(--dl-color-primary-azul);
  font-family: Montserrat;
  text-decoration: underline;
}
.home-text38 {
  left: 0px;
  color: var(--dl-color-primary-azul);
  right: 0px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  align-self: flex-end;
  text-align: center;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image1 {
    width: 80%;
    height: auto;
    align-self: center;
  }
  .home-image2 {
    width: 80%;
    height: auto;
    align-self: center;
  }
  .home-text16 {
    font-size: 24px;
  }
  .home-text17 {
    font-size: 24px;
  }
  .home-text21 {
    font-size: 24px;
  }
  .home-text28 {
    font-size: 24px;
  }
  .home-image3 {
    width: 50%;
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .home-container02 {
    display: none;
  }
  .home-icon-group {
    display: none;
  }
  .home-container06 {
    align-self: center;
  }
  .home-text06 {
    align-self: center;
  }
  .home-image1 {
    width: 90%;
    height: auto;
    align-self: center;
  }
  .home-image2 {
    width: 90%;
    height: auto;
    align-self: center;
  }
  .home-container15 {
    position: relative;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-text15 {
    align-self: center;
    text-align: center;
  }
  .home-container16 {
    height: 500px;
  }
  .home-container19 {
    height: 33%;
  }
  .home-text16 {
    font-size: 18px;
    text-align: center;
  }
  .home-container21 {
    height: 33%;
  }
  .home-text17 {
    font-size: 18px;
    text-align: center;
  }
  .home-container24 {
    height: 33%;
  }
  .home-text21 {
    font-size: 18px;
  }
  .home-container26 {
    height: 33%;
  }
  .home-text28 {
    font-size: 18px;
  }
  .home-image3 {
    width: 33%;
  }
}
</style>
