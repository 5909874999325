<template>
  <div class="poltica-privacidade-container">
    <div class="poltica-privacidade-container01">
      <header data-role="Header" class="poltica-privacidade-header">
        <img
          alt="logo"
          src="/playground_assets/f464d58c0ac4465a6d2141623af20120-1400w.png"
          class="poltica-privacidade-image"
        />
        <div class="poltica-privacidade-container02">
          <div class="poltica-privacidade-container03">
            <router-link to="/" class="poltica-privacidade-navlink">
              Quem somos
            </router-link>
            <router-link to="/" class="poltica-privacidade-navlink1">
              Áreas de atuação
            </router-link>
            <router-link to="/" class="poltica-privacidade-navlink2">
              Contato
            </router-link>
          </div>
        </div>
        <div class="poltica-privacidade-icon-group">
          <a
            href="https://api.whatsapp.com/send/?phone=5553933002415&amp;text&amp;type=phone_number&amp;app_absent=0"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              viewBox="0 0 877.7142857142857 1024"
              class="poltica-privacidade-icon"
            >
              <path
                d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/hdd.adv/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              viewBox="0 0 877.7142857142857 1024"
              class="poltica-privacidade-icon02"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/hdd-adv/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <svg
              viewBox="0 0 877.7142857142857 1024"
              class="poltica-privacidade-icon04"
            >
              <path
                d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
              ></path>
            </svg>
          </a>
        </div>
      </header>
    </div>
    <div class="poltica-privacidade-container04">
      <span class="poltica-privacidade-text">Política de Privacidade</span>
    </div>
    <span class="poltica-privacidade-text001">
      <span class="poltica-privacidade-text002">
        Política de Privacidade do site de Hamilton, Damas &amp; De Macedo
        Advocacia - hdd.adv.br
      </span>
      <br class="poltica-privacidade-text003" />
      <br />
      <span>
        Nosso escritório respeita a sua autonomia para compartilhar (ou não) seus
        da-dos pessoais. Com isto em mente, disponibilizamos esta política para
        que você tenha amplo conhecimento sobre o que acontece com os seus dados
        pessoais quando você acessa nosso site. A finalidade geral da coleta de
        dados pessoais em nosso site é construir um relacionamento com os clientes
        que assim con-sentirem.
      </span>
      <br />
      <br />
      <span>___________________________</span>
      <br />
      <br />
      <span>
        Este documento traz a política de privacidade do site de Hamilton, Damas
        &amp; De Macedo Advocacia. Sua finalidade, em consonância com a Lei n°
        13.709, Lei Geral de Proteção de Dados Pessoais (LGPD), é descrever a
        maneira como se dá a co-leta, armazenamento e tratamento dos dados
        pessoais especificamente em nosso site, bem como explicitar os direitos do
        titular que poderão ser exercidos.
      </span>
      <br />
      <br />
      <span>
        A sociedade tem sua sede comercial estabelecida à Rua Barão de Cotegipe,
        n°443, Sala 612, Centro, Rio Grande, RS, CEP 96200-290, e está
        regularmente inscrita na OAB/RS sob o n° 10.633.
      </span>
      <br />
      <br />
      <span>
        Ao fornecer os seus dados pessoais em nosso site, você aceita os termos e
        con-dições desta Política de Privacidade e Proteção de Dados Pessoais,
        lembrando que apenas serão tratados os dados pessoais necessários às
        finalidades que embasam sua coleta, nos termos do artigo 7° da LGPD.
        Também é importante salientar que alguns dados poderão ser mantidos para
        cumprimento de obriga-ção legal ou regulatória, exercício regular de
        direito, interesse legítimo ou com o seu consentimento.
      </span>
      <br />
      <br />
      <span>
        O presente documento foi dividido nas seguintes partes, como forma de
        facili-tar a sua leitura e interpretação: 1) Coleta de informação pessoal;
        2) Uso de sua informação pessoal; 3) Compartilhamento de informações com
        terceiros; 4) Se-gurança de seus dados; 5) Direitos dos usuários; 6)
        Atualizações da política de privacidade do site; 7) Consentimento; e 8)
        Encarregado de dados.
      </span>
      <br />
      <br />
      <span>
        Devido a nossa constante atualização, esta Política de Privacidade e
        Proteção de Dados pode sofrer alterações, de forma que a versão mais
        recente estará disponível diretamente em nosso site.
      </span>
      <br />
      <br />
      <span class="poltica-privacidade-text026">
        1. Coleta de Informação Pessoal
      </span>
      <br class="poltica-privacidade-text027" />
      <br />
      <span>
        Nosso site disponibiliza a seção “Receba nossas publicações por email” nas
        pá-ginas “Home” e “Contato”. Caso você preencha esta seção e envie seus
        dados, utilizaremos seu nome completo e email estritamente para a
        finalidade de in-clusão na lista de destinatários dos emails do HDD, para
        recebimento dos in-formativos para clientes.
      </span>
      <br />
      <br />
      <span>
        Nosso site utiliza a plataforma de hospedagem Wix, assim como a plataforma
        de análise Google Analytics, as quais nos disponibilizam relatórios
        estatísticos com dados anonimizados, demonstrando o número de sessões de
        acesso ao site, número total de visitantes, número de visitantes únicos e
        demais informa-ções padronizadas de análise estatística. Nosso escritório
        tem acesso estrita-mente a estes dados estatísticos anônimos.
      </span>
      <br />
      <br />
      <span>
        Para que a estatística possa ser visualizada, no entanto, o Wix e o Google
        têm acesso a algumas informações técnicas do seu dispositivo, tais como,
        endereço IP e Porta Lógica de Origem, tipo de dispositivo, navegador e
        sistema operacio-nal, registros de data e horário de cada ação que você
        realizar, telas acessadas, ID da sessão e Cookies.
      </span>
      <br />
      <br />
      <span>
        Ao clicar nos links de contato em plataformas de terceiros (WhatsApp,
        Insta-gram, Facebook e LinkedIn), você será redirecionado para o sistema
        correspon-dente, estando sujeito à política de privacidade da plataforma
        em questão. No entanto, poderemos coletar seus dados de contato na
        respectiva plataforma para que possamos atendê-lo, caso você decida
        compartilhar seus dados. Por exemplo: no WhatsApp, você pode informar o
        número de telefone e mostrar seu perfil ao iniciar uma conversa. No
        Linkedin, seu perfil pode aparecer como visualizações dos nossos perfis;
        no Facebook você pode fazer um pedido de amizade; no Instagram você pode
        seguir nossos perfis.
      </span>
      <br />
      <br />
      <span class="poltica-privacidade-text041">
        2. Uso da sua informação pessoal
      </span>
      <br class="poltica-privacidade-text042" />
      <br />
      <span>
        Utilizamos os dados coletados para informar sobre novidades,
        funcionalidades, conteúdos, notícias e eventos relacionados aos nossos
        serviços. Ainda, os dados também são utilizados para a sua proteção, seja
        para cumprir obrigações legais e assim assegurar seus direitos, ou até
        mesmo para prevenir alguma fraude.
      </span>
      <br />
      <br />
      <span>
        A qualquer momento você pode optar em não receber mais informações,
        bas-tando para isso solicitar via nosso endereço de e-mail ou seguindo as
        instruções de retirada de inscrição que acompanham as mensagens recebidas.
      </span>
      <br />
      <br />
      <span class="poltica-privacidade-text050">
        3. Compartilhamento de informações com terceiros
      </span>
      <br class="poltica-privacidade-text051" />
      <br />
      <span>
        As únicas hipóteses em que o HDD disponibilizará suas informações pessoais
        com terceiros são as seguintes: (a) legislação, regulamentação, processo
        legal ou solicitação governamental aplicável; (b) cumprir investigação de
        possíveis violações; (c) fraude ou por segurança; ou (d) proteger contra
        dano aos direitos, a propriedade ou a segurança do HDD, nossos usuários ou
        o público, conforme solicitado ou permitido por lei.
      </span>
      <br />
      <br />
      <br />
      <span class="poltica-privacidade-text057">4. Segurança de seus Dados</span>
      <br class="poltica-privacidade-text058" />
      <br />
      <span>
        Dispomos de medidas de segurança em âmbitos físico, eletrônico e
        administra-tivo, que protegem os dados pessoais e suas informações. Essas
        medidas de proteção nos auxiliam na prevenção de fraudes e acessos não
        autorizados às informações, bem como na manutenção da integridade dos
        dados. Além disso, dispomos de uma Política de Segurança Interna para que
        seus dados permane-çam sempre em segurança.
      </span>
      <br />
      <br />
      <span>
        Ademais, nosso site tem certificado de segurança e, especificamente, os
        dados pessoais coletados no site são armazenados diretamente na plataforma
        do Wix em conjunto com a plataforma do Google Workspace (G-Suite).
      </span>
      <br />
      <br />
      <span class="poltica-privacidade-text066">5. Direitos dos usuários</span>
      <br class="poltica-privacidade-text067" />
      <br />
      <span>
        Os direitos elencados abaixo podem ser exercidos a qualquer momento
        através do e-mail: contato@hdd.adv.br.
        <span v-html="rawmsz0"></span>
      </span>
      <br />
      <br />
      <span>
        Acesso: o usuário poderá solicitar informações a respeito de quais dados
        são armazenados e para qual finalidade.
      </span>
      <br />
      <br />
      <span>
        Retificação: o usuário poderá solicitar a correção/atualização de algum de
        seus dados pessoais, pois é sua responsabilidade mantê-los sempre
        atualizados.
        <span v-html="rawq1k7"></span>
      </span>
      <br />
      <br />
      <span>
        Eliminação: o usuário poderá solicitar a eliminação dos seus dados
        pessoais nas seguintes situações: (i) quando não forem necessários para a
        finalidade para a qual são utilizados, (ii) quando não estiver mais
        consentindo com os termos aqui expostos.
      </span>
      <br />
      <br />
      <br />
      <span class="poltica-privacidade-text082">
        6. Atualizações na Política de Privacidade do Site
      </span>
      <br class="poltica-privacidade-text083" />
      <br />
      <span>
        Em caso de modificação nos termos, publicaremos a nova versão da política
        de privacidade em nosso site. As versões anteriores da Política de
        Privacidade do Site serão arquivadas para eventual comparação.
      </span>
      <br />
      <br />
      <br />
      <span class="poltica-privacidade-text089">7. Consentimento</span>
      <br class="poltica-privacidade-text090" />
      <br />
      <span>
        Ao aceitar receber nossas publicações por email, você está consentindo o
        aces-so a seus dados pessoais informados para a finalidade de contato.
      </span>
      <br />
      <br />
      <span class="poltica-privacidade-text095">8. Encarregado de Dados</span>
      <br class="poltica-privacidade-text096" />
      <br />
      <span>
        Durante o período de armazenamento dos dados pelo HDD haverá um
        respon-sável pelos dados, nos termos do artigo 41 da LGPD. Esta pessoa irá
        responder às solicitações dos titulares dos dados, e também caso haja
        necessidade de apresentá-los para autoridade competente.
      </span>
      <br />
      <br />
      <span>
        Dados do contato do encarregado:
        <span v-html="rawj39v"></span>
      </span>
      <br />
      <span>Melissa Pereira das Neves de Macedo, e-mail: contato@hdd.adv.br</span>
      <br />
      <br />
      <br />
      <span>
        Se, mesmo assim, você ficar com alguma dúvida entre em contato através do
        e-mail: contato@hdd.adv.br.
      </span>
      <br />
      <br />
      <br />
      <span>Versão 1.0.</span>
      <br />
      <span>Rio Grande, 27 de abril de 2021.</span>
      <br />
      <br />
      <br />
      <br />
    </span>
    <div class="poltica-privacidade-container05"></div>
    <div class="poltica-privacidade-container06">
      <span id="contato" class="poltica-privacidade-text118">Contato</span>
    </div>
    <div class="poltica-privacidade-container07">
      <div class="poltica-privacidade-container08">
        <div class="poltica-privacidade-container09">
          <router-link to="/" class="poltica-privacidade-navlink3">
            <img
              alt="image"
              src="/playground_assets/0aaf9533daed1a66a8b67b2ecae6c7ab-200h.png"
              class="poltica-privacidade-image1"
            />
          </router-link>
          <div class="poltica-privacidade-container10">
            <div class="poltica-privacidade-icon-group1">
              <a
                href="https://api.whatsapp.com/send/?phone=5553933002415&amp;text&amp;type=phone_number&amp;app_absent=0"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="poltica-privacidade-icon06"
                >
                  <path
                    d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/hdd.adv/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="poltica-privacidade-icon08"
                >
                  <path
                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/hdd-adv/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  class="poltica-privacidade-icon10"
                >
                  <path
                    d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div class="poltica-privacidade-container11">
            <a
              href="https://api.whatsapp.com/send/?phone=5553933002415&amp;text&amp;type=phone_number&amp;app_absent=0"
              target="_blank"
              rel="noreferrer noopener"
              class="poltica-privacidade-link6"
            >
              <span>(53) 93300-2415</span>
              <br />
              <span>Atendimento com hora marcada</span>
            </a>
          </div>
        </div>
        <a
          href="https://hdd.adv.br/pdf/PoliticaPrivacidade.pdf"
          target="_blank"
          rel="noreferrer noopener"
          class="poltica-privacidade-link7"
        >
          Termos de uso do site
        </a>
      </div>
      <span class="poltica-privacidade-text122">
        <span>Porto de Gale Business Center, sala 612</span>
        <br />
        <span>Rua Barão de Cotegipe, nº 443, Centro, Rio Grande/RS</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PolticaPrivacidade',

  data() {
    return {
      rawmsz0: ' ',
      rawq1k7: ' ',
      rawj39v: ' ',
    }
  },

  metaInfo: {
    title: 'Poltica-Privacidade - Hamilton, Damas & De Macedo Advocacia',
    meta: [
      {
        name: 'description',
        content:
          'Advogado Advogada Direito Civil Família Sucessões Herança Inventário Doação Testamento Compra Venda Imóvel Planejamento Cartório Justiça Processo Contrato',
      },
      {
        property: 'og:title',
        content: 'Poltica-Privacidade - Hamilton, Damas & De Macedo Advocacia',
      },
      {
        property: 'og:description',
        content:
          'Advogado Advogada Direito Civil Família Sucessões Herança Inventário Doação Testamento Compra Venda Imóvel Planejamento Cartório Justiça Processo Contrato',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/4c2dbd79-e313-455e-8bdb-dc5001b85970/270b3fb6-aaa5-49f0-bc10-b73e2c77e900?org_if_sml=1',
      },
    ],
  },
}
</script>

<style scoped>
.poltica-privacidade-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-primary-amarelo);
}
.poltica-privacidade-container01 {
  width: 100%;
  height: 201px;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  background-color: #15294e;
}
.poltica-privacidade-header {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.poltica-privacidade-image {
  height: 75%;
  object-fit: cover;
}
.poltica-privacidade-container02 {
  flex: 0 0 auto;
  width: 50%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.poltica-privacidade-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.poltica-privacidade-navlink {
  color: var(--dl-color-primary-amarelo);
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
  padding-right: var(--dl-space-space-sixunits);
  text-decoration: none;
}
.poltica-privacidade-navlink1 {
  color: var(--dl-color-primary-amarelo);
  align-self: center;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
  padding-right: var(--dl-space-space-sixunits);
  text-decoration: none;
}
.poltica-privacidade-navlink2 {
  color: var(--dl-color-primary-amarelo);
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
  text-decoration: none;
}
.poltica-privacidade-icon {
  fill: var(--dl-color-primary-amarelo);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.poltica-privacidade-icon02 {
  fill: var(--dl-color-primary-amarelo);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.poltica-privacidade-icon04 {
  fill: var(--dl-color-primary-amarelo);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.poltica-privacidade-container04 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.poltica-privacidade-text {
  color: var(--dl-color-primary-azul);
  font-size: 42px;
  font-family: Montserrat;
  font-weight: 800;
}
.poltica-privacidade-text001 {
  color: var(--dl-color-primary-azul);
  margin: var(--dl-space-space-twounits);
  font-family: Montserrat;
}
.poltica-privacidade-text002 {
  font-style: normal;
  font-weight: 800;
}
.poltica-privacidade-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-azul);
}
.poltica-privacidade-container06 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.poltica-privacidade-text118 {
  color: var(--dl-color-primary-azul);
  font-size: 42px;
  font-family: Montserrat;
  font-weight: 800;
  text-transform: capitalize;
}
.poltica-privacidade-container07 {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--dl-color-primary-amarelo);
}
.poltica-privacidade-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.poltica-privacidade-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.poltica-privacidade-navlink3 {
  display: contents;
}
.poltica-privacidade-image1 {
  width: 66%;
  height: auto;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.poltica-privacidade-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.poltica-privacidade-icon-group1 {
  align-self: center;
}
.poltica-privacidade-icon06 {
  fill: var(--dl-color-primary-azul);
  width: var(--dl-size-size-small);
  height: auto;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.poltica-privacidade-icon08 {
  fill: var(--dl-color-primary-azul);
  width: var(--dl-size-size-small);
  height: auto;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.poltica-privacidade-icon10 {
  fill: var(--dl-color-primary-azul);
  width: var(--dl-size-size-small);
  height: auto;
  text-decoration: none;
}
.poltica-privacidade-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.poltica-privacidade-link6 {
  color: var(--dl-color-primary-azul);
  font-size: 24px;
  align-self: center;
  text-align: center;
  font-family: Montserrat;
  text-decoration: none;
}
.poltica-privacidade-link7 {
  color: var(--dl-color-primary-azul);
  font-family: Montserrat;
  text-decoration: underline;
}
.poltica-privacidade-text122 {
  left: 0px;
  color: var(--dl-color-primary-azul);
  right: 0px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  align-self: flex-end;
  text-align: center;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
@media(max-width: 767px) {
  .poltica-privacidade-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .poltica-privacidade-image1 {
    width: 50%;
  }
}
@media(max-width: 479px) {
  .poltica-privacidade-header {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .poltica-privacidade-container02 {
    display: none;
  }
  .poltica-privacidade-icon-group {
    display: none;
  }
  .poltica-privacidade-text001 {
    color: var(--dl-color-primary-azul);
    font-family: Montserrat;
  }
  .poltica-privacidade-text002 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
  }
  .poltica-privacidade-text003 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
  }
  .poltica-privacidade-text026 {
    text-decoration: underline;
  }
  .poltica-privacidade-text027 {
    text-decoration: underline;
  }
  .poltica-privacidade-text041 {
    text-decoration: underline;
  }
  .poltica-privacidade-text042 {
    text-decoration: underline;
  }
  .poltica-privacidade-text050 {
    text-decoration: underline;
  }
  .poltica-privacidade-text051 {
    text-decoration: underline;
  }
  .poltica-privacidade-text057 {
    text-decoration: underline;
  }
  .poltica-privacidade-text058 {
    text-decoration: underline;
  }
  .poltica-privacidade-text066 {
    text-decoration: underline;
  }
  .poltica-privacidade-text067 {
    text-decoration: underline;
  }
  .poltica-privacidade-text082 {
    text-decoration: underline;
  }
  .poltica-privacidade-text083 {
    text-decoration: underline;
  }
  .poltica-privacidade-text089 {
    text-decoration: underline;
  }
  .poltica-privacidade-text090 {
    text-decoration: underline;
  }
  .poltica-privacidade-text095 {
    text-decoration: underline;
  }
  .poltica-privacidade-text096 {
    text-decoration: underline;
  }
  .poltica-privacidade-image1 {
    width: 33%;
  }
}
</style>
